import ExecutionEnvironment from "@docusaurus/ExecutionEnvironment";
import * as Sentry from "@sentry/react";

if (ExecutionEnvironment.canUseDOM) {
  const dsn = (window as any).__SENTRY_DSN__;
  Sentry.init({
    dsn,
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: 0.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
