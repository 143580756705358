import mixpanel, { Mixpanel } from "mixpanel-browser";

let instance: Mixpanel;

export function initializeMixpanel() {
  if (instance) throw new Error("Mixpanel is already initialized");
  const token = (window as any).__MIXPANEL_TOKEN__;
  if (!token) throw new Error("Mixpanel token is not set");
  instance = mixpanel.init(
    token,
    {
      debug: false,
      track_pageview: false,
      persistence: "localStorage",
      loaded: function (mixpanelInstance) {
        instance = mixpanelInstance;
      },
    },
    "docusaurus_mixpanel_instance"
  );
  return instance;
}

export function getMixpanel() {
  return instance || initializeMixpanel();
}
