import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/admin',
    component: ComponentCreator('/admin', '8d3'),
    exact: true
  },
  {
    path: '/search',
    component: ComponentCreator('/search', '822'),
    exact: true
  },
  {
    path: '/',
    component: ComponentCreator('/', 'a6b'),
    routes: [
      {
        path: '/',
        component: ComponentCreator('/', '694'),
        routes: [
          {
            path: '/tags',
            component: ComponentCreator('/tags', 'ce1'),
            exact: true
          },
          {
            path: '/tags/homepage',
            component: ComponentCreator('/tags/homepage', '602'),
            exact: true
          },
          {
            path: '/tags/neatleaf',
            component: ComponentCreator('/tags/neatleaf', 'c3f'),
            exact: true
          },
          {
            path: '/tags/plant-science',
            component: ComponentCreator('/tags/plant-science', '57b'),
            exact: true
          },
          {
            path: '/tags/wiki',
            component: ComponentCreator('/tags/wiki', '403'),
            exact: true
          },
          {
            path: '/',
            component: ComponentCreator('/', 'aae'),
            routes: [
              {
                path: '/',
                component: ComponentCreator('/', '631'),
                exact: true,
                sidebar: "mainSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
