import lunr from "/builds/neatleaf/wiki/node_modules/lunr/lunr.js";
export const language = ["en"];
export const removeDefaultStopWordFilter = false;
export const removeDefaultStemmer = false;
export { default as Mark } from "/builds/neatleaf/wiki/node_modules/mark.js/dist/mark.js"
export const searchIndexUrl = "search-index{dir}.json?_=3e3b07f8";
export const searchResultLimits = 8;
export const searchResultContextMaxLength = 50;
export const explicitSearchResultPath = false;
export const searchBarShortcut = true;
export const searchBarShortcutHint = false;
export const searchBarPosition = "right";
export const docsPluginIdForPreferredVersion = undefined;
export const indexDocs = true;
export const searchContextByPaths = null;
export const hideSearchBarWithNoSearchContext = false;
export const useAllContextsWithNoSearchContext = false;