import type { ClientModule } from "@docusaurus/types";
import { getMixpanel } from "./mixpanelSetup";

const clientModule: ClientModule = {
  onRouteUpdate({ location }) {
    getMixpanel().track("Page View", {
      page: document.title,
      path: location.pathname,
      hash: location.hash,
    });
  },
};

export default clientModule;
