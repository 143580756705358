import {
  PageMetadata,
  SkipToContentFallbackId,
  ThemeClassNames,
} from "@docusaurus/theme-common";
import { useKeyboardNavigation } from "@docusaurus/theme-common/internal";
import * as Sentry from "@sentry/react";
import AnnouncementBar from "@theme/AnnouncementBar";
import Footer from "@theme/Footer";
import type { Props } from "@theme/Layout";
import LayoutProvider from "@theme/Layout/Provider";
import Navbar from "@theme/Navbar";
import SkipToContent from "@theme/SkipToContent";
import clsx from "clsx";
import React from "react";
import styles from "./styles.module.css";

export default function Layout(props: Props): JSX.Element {
  const {
    children,
    noFooter,
    wrapperClassName,
    // Not really layout-related, but kept for convenience/retro-compatibility
    title,
    description,
  } = props;

  useKeyboardNavigation();

  return (
    <LayoutProvider>
      <PageMetadata title={title} description={description} />

      <SkipToContent />

      <AnnouncementBar />

      <Navbar />

      <div
        id={SkipToContentFallbackId}
        className={clsx(
          ThemeClassNames.wrapper.main,
          styles.mainWrapper,
          wrapperClassName
        )}
      >
        <Sentry.ErrorBoundary
          showDialog
          fallback={(params) => (
            <div>
              <h1>Oops, something went wrong!</h1>
              <br />
              If the error continues, please email support@neatleaf.com
              <br />
              <br />
              <button
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Go to home page
              </button>
            </div>
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      </div>

      {!noFooter && <Footer />}
    </LayoutProvider>
  );
}
